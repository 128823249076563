// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-blockparty-js": () => import("./../src/pages/blockparty.js" /* webpackChunkName: "component---src-pages-blockparty-js" */),
  "component---src-pages-course-registration-js": () => import("./../src/pages/course-registration.js" /* webpackChunkName: "component---src-pages-course-registration-js" */),
  "component---src-pages-hostmost-js": () => import("./../src/pages/hostmost.js" /* webpackChunkName: "component---src-pages-hostmost-js" */),
  "component---src-pages-ignytion-js": () => import("./../src/pages/ignytion.js" /* webpackChunkName: "component---src-pages-ignytion-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovation-garage-js": () => import("./../src/pages/innovation-garage.js" /* webpackChunkName: "component---src-pages-innovation-garage-js" */),
  "component---src-pages-scalar-js": () => import("./../src/pages/scalar.js" /* webpackChunkName: "component---src-pages-scalar-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

